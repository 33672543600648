export const hotelIds = [
  "303",
  "375",
  "421",
  "501",
  "625",
  "668",
  "702",
  "895",
  "921",
  "1015",
  "1106",
  "1523",
  "1847",
  "1980",
  "2015",
  "2100",
  "2298",
  "2341",
  "2450",
  "2458",
];
