import axios from "axios";
import pixelArtData1 from "../assets/_metadata1.json";
import pixelArtData2 from "../assets/_metadata2.json";
import pixelArtData3 from "../assets/_metadata3.json";
import pixelArtData4 from "../assets/_metadata4.json";
import { nftaddress, pixeladdress } from "./metahouse";

const pixelArtData = [...pixelArtData1, ...pixelArtData2, ...pixelArtData3, ...pixelArtData4];

const API_KEY = process.env.REACT_APP_ALCHEMY_API_KEY;

export const getUserNfts = async (address: string, pageKey?: string) => {
  try {
    const { data } = await axios.get(
      `https://eth-mainnet.g.alchemy.com/nft/v2/${API_KEY}/getNFTs?owner=${address}&contractAddresses[]=${nftaddress}&withMetadata=true&tokenType=ERC721&pageKey=${pageKey}`
    );

    const nftImages = await Promise.all(
      data.ownedNfts.map(async (d) => {
        if (!d.metadata) {
          return {
            token_id: parseInt(d.id.tokenId, 16),
            image: "",
          };
        }

        const res = d.metadata;

        if (res?.metadata?.length === 0) {
          return {
            token_id: parseInt(d.id.tokenId, 16),
            image: "",
          };
        }

        const image2 = res.image.replace("ipfs://", "ipfs/");
        return {
          token_id: parseInt(d.id.tokenId, 16),
          image: `https://ipfs.io/${image2}`,
        };
      })
    );
    console.log(nftImages);
    return nftImages;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserStakedNfts = async (data: any[]) => {
  try {
    const nftImages = await Promise.all(
      data.map(async (d) => {
        const { data } = await axios.get(`
https://eth-mainnet.g.alchemy.com/nft/v2/${API_KEY}/getNFTMetadata?contractAddress=${nftaddress}&tokenId=${d}&tokenType=ERC721&refreshCache=false
`);

        const imageUri = data.metadata.image.replace("ipfs://", "ipfs/");

        return {
          token_id: d,
          image: imageUri,
        };
      })
    );

    return nftImages;
  } catch (error) {
    console.log(error);
  }
};

export const getUserPixelNfts = async (address: string) => {
  try {
    const { data } = await axios.get(
      `https://eth-mainnet.g.alchemy.com/nft/v2/${API_KEY}/getNFTs?owner=${address}&contractAddresses[]=${pixeladdress}&withMetadata=true&tokenType=ERC721`
    );

    const nftImages = await Promise.all(
      data.ownedNfts.map(async (d) => {
        const token_id = d.id.tokenId.startsWith("0x")
          ? parseInt(d.id.tokenId, 16)
          : Number(d.id.tokenId);

        const res = pixelArtData[token_id];
        const image2 = res.image.replace("ipfs://", "ipfs/");
        return {
          token_id,
          image: `https://ipfs.io/${image2}`,
        };
      })
    );

    return nftImages;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserStakedPixelNfts = async (data: any[]) => {
  try {
    const nftImages = await Promise.all(
      data.map(async (d) => {
        const res = pixelArtData[d];
        const image2 = res.image.replace("ipfs://", "ipfs/");

        return {
          token_id: d,
          image: `https://ipfs.io/${image2}`,
        };
      })
    );

    return nftImages;
  } catch (error) {
    console.log(error);
  }
};
